<template>
  <div>
    <el-upload
        action="#"
        list-type="picture-card"
        accept="video/*"
        :multiple="true"
        :on-change="handleSuccess"
        :file-list="files"
        :auto-upload="false">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}" style="padding: 8px">
          <video
              controls
              preload="none"
              webkit-playsinline="true"
              playsinline="true"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x-webkit-airplay="allow"
              x5-video-orientation="portraint"
              style="object-fit:fill;width: 100%;height: 100%;">
            <source :src="file.url"
                    type="video/mp4">
            <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
          </video>

        <el-button icon="el-icon-delete" @click="handleRemove(file)" size="mini"
                       style="background-color: var(--my-error-color);color: whitesmoke">删除视频</el-button>

      </div>
    </el-upload>
  </div>
</template>
<script>

export default {
  name: "store-style-add",
  components: {

  },
  created() {

  },
  props:{
    imgSize:{
      type:Number,
      required: true,
    }
  },
  data() {
    return {
      files:[],
    }
  },
  methods:{
    handleSuccess(file){
      this.files.push(file)
      if (this.imgSize>0&&this.files.length>this.imgSize){
        this.$message.warning("超出上传数量限制!")
        this.files = this.files.filter(obj => obj.url !== file.url);
        return;
      }
      this.$emit("files",this.files)
    },
    handleRemove(file) {
      this.files = this.files.filter(obj => obj.url !== file.url);
      this.$emit("files",this.files)
    },
    handlePictureCardPreview(file) {
      this.$viewerApi({
        images: [file.url],
      })
    },
  },
}
</script>
<style scoped>

</style>