<template>
  <el-dialog
      title="款式信息"
      width="80%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row>
      <el-col :span="9">
        <styleStoreImage v-if="true" :styleInfo="styleInfo" :state="true" @close="close"/>
      </el-col>
      <el-col :span="11">
        <el-form label-width="130px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式类型" prop="styleType">
                <el-input readonly v-model="form.styleType" class="searchParam"/>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="isMe">
              <el-form-item label="货品状态">
                <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    v-model="form.publishStatus"
                    active-text="上架"
                    inactive-text="下架">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式品牌">
                <el-input readonly v-model="form.brandName" class="searchParam"/>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式价格" >
                <el-input v-model="form.salePrice" type="number" class="searchParam"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建议零售价" >
                <el-input v-model="form.suggestSalePrice" type="number" class="searchParam"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式系列">
                <el-select v-model="form.storeSeriesId" :disabled="!isMe" filterable placeholder="选择系列" clearable @change="seriesChange"  class="searchParam">
                  <el-option
                      v-for="item in seriesIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="款式系列编号" prop="factoryName">
                <el-input v-model="form.storeSeriesNum" :readonly="true" class="searchParam"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式名称">
                <el-input v-model="form.styleName" placeholder="请输入款式名称"  class="searchParam"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="款式编号" prop="factoryName">
                <el-input v-model="form.styleNum" placeholder="请输入款式编号"  class="searchParam"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="款式标签" class="labelClass">
                <el-select v-model="form.storeLabelIds" :disabled="!isMe" filterable multiple placeholder="选择标签" clearable>
                  <el-option
                      v-for="item in labelNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="款式介绍" prop="styleInfo">
                <el-input type="textarea" :readonly="!isMe" v-model="form.styleInfo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="适合身形" prop="styleFit">
                <el-input type="textarea" :readonly="!isMe" v-model="form.styleFit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="不适合身形" prop="styleNoFit">
                <el-input type="textarea" :readonly="!isMe" v-model="form.styleNoFit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row style="text-align: center" v-show="isMe">
          <el-button type="primary" icon="el-icon-edit-outline" @click="updateById()">修改</el-button>
        </el-row>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import styleStoreImage from "@/pages/store/style-store/style-store-image";
export default {
  name: "style-store-details.vue",
  data() {
    return {
      form:{
        styleType:this.styleInfo.storeTypeName,
        salePrice:this.styleInfo.salePrice,
        suggestSalePrice:this.styleInfo.suggestSalePrice,
        publishStatus:this.styleInfo.publishStatus,
        storeLabelIds:[],
        storeSeriesId:this.styleInfo.storeSeriesId,
        storeSeriesNum:this.styleInfo.storeSeriesNum,
        styleName:this.styleInfo.styleName,
        styleNum:this.styleInfo.styleNum,
        brandName:this.styleInfo.storeBrandName,
        styleInfo:this.styleInfo.styleInfo,
        styleFit:this.styleInfo.styleFit,
        styleNoFit:this.styleInfo.styleNoFit,
      },
      brandNameArray:[],
      labelNameArray:[],
      seriesIdArray:[],
      seriesNumIdArray:[],
      dialogState: this.state,

      tenantCrop:localStorage.getItem("tenantCrop"),
      //该款式是此登陆店铺的吗？
      isMe:false,
    }
  },
  components:{
    styleStoreImage
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  watch:{
  },
  created() {
    console.log(this.styleInfo)
    this.queryLabelNames();
    this.queryStoreStyleSeries();
    this.isMe=this.tenantCrop===this.styleInfo.tenantCrop
  },
  methods: {
    updateById(){

      this.$confirm('是否确认修改?', '修改款式', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        this.$axios({
          method:"POST",
          url:"/storeStyle/updateStoreStyle",
          data:{
            id:this.styleInfo.id,
            salePrice:this.form.salePrice,
            suggestSalePrice:this.form.suggestSalePrice,
            publishStatus:this.form.publishStatus,
            storeSeriesId:this.form.storeSeriesId,
            styleName:this.form.styleName,
            styleNum:this.form.styleNum,
            storeLabelIds:this.form.storeLabelIds,
            styleInfo:this.form.styleInfo,
            styleFit:this.form.styleFit,
            styleNoFit:this.form.styleNoFit,
          }
        }).then(response=>{
          if(response.data.code===200){
            this.$message.success('修改成功');
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
            return;
          }
          this.$message.error(response.data.msg);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消添加'
        });
      });
    },

    //查询标签类型
    queryLabelNames(){
      this.$selectUtils.queryStoreLabelIds().then(response=>{
        this.labelNameArray=JSON.parse(response.data.data);
        this.styleInfo.labelIds.split(",").forEach(k=>{
          this.form.storeLabelIds.push(Number(k));
        })


      })
    },
    //查询款式系列
    queryStoreStyleSeries(){
      this.$selectUtils.queryStoreSeriesIds(this.styleInfo.storeBrandId).then(response=>{
        this.seriesIdArray=JSON.parse(response.data.data);
      })
    },
    seriesChange(val){
      this.$axios({
        method:"GET",
        url:"/storeSeries/queryById",
        params:{
          id:val
        }
      }).then(response=>{
        this.form.storeSeriesNum=response.data.data.seriesNum

      })
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>
.searchParam{
  width: 150px;
}
.labelClass  .el-select{
  display:block;
}
</style>