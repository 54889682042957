<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select v-model="form.storeTypeId" filterable placeholder="款式类型" clearable class="searchParam"
                     size="medium">
            <el-option
                v-for="item in storeStyleTypeIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.storeLabelIds" filterable multiple placeholder="款式标签" clearable
                     class="searchParam" size="medium">
            <el-option
                v-for="item in storeLabelIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" v-show="tenantCrop==='2a31c23a-c178-441614928053489'">
          <el-select v-model="form.publishStatus" placeholder="上架状态" clearable class="searchParam" size="medium">
            <el-option label="全部" value=""></el-option>
            <el-option label="未上架" value="0"></el-option>
            <el-option label="已上架" value="1"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <div class="infinite-list-wrapper">
      <el-row :gutter="20" style="margin: 0 auto;" class="list" v-infinite-scroll="load"
              :infinite-scroll-disabled="disabled">
        <el-col :span="4" v-for="item in styleList" :key="item.id" style="margin: 1% 0 0 0" class="list-item">
          <el-badge :value="item.publishStatus===0?'下架':item.publishStatus===1?'在售':'已出售'" class="item"
                    :type="item.publishStatus===0?'warning':item.publishStatus===1?'success':'primary'">
            <div style="width: 14vw;height: 19vw">
              <el-image
                  style="width: 100%; height: 100%;cursor:zoom-in;" @click="openStyleImages(item.id)"
                  :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.mainImage+'?imageMogr2/rquality/60'"
                  fit="contain"/>
            </div>
          </el-badge>
          <div style="width: 14vw;">
            <el-row>
              <el-col :span="24" style="text-align: center">
                <el-dropdown trigger="click" @command="handleCommand">
                  <span class="el-dropdown-link" style="cursor:pointer;font-size: 13px;font-weight: bold;">
                    操作 <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-edit" :command="['edit',item]">款式信息</el-dropdown-item>
                    <el-dropdown-item v-if="item.tenantCrop===tenantCrop" icon="el-icon-delete" :command="['del',item]">删除款式</el-dropdown-item>
                    <!--                                      <el-dropdown-item icon="el-icon-picture-outline" :command="['styleImage',item]">款式图片</el-dropdown-item>-->
                    <!--                    <el-dropdown-item icon="el-icon-price-tag" :command="['stylePrice',item]">款式价格</el-dropdown-item>-->
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <p class="pTitle" style="text-align: left;font-weight: bold;font-size: 13px">
                  {{ ['',undefined].includes(item.styleName)?'无款式名称':item.styleName }}
                </p>
              </el-col>
              <el-col :span="12">
                <p class="pTitle" style="text-align: right;font-weight: bold;font-size: 13px">
                  {{ item.salePrice === '' ? '暂未标价' : '¥ '+item.salePrice }}</p>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <p class="pTitle" style="text-align: left;font-weight: bold;font-size: 13px">
                  {{ item.storeSeriesName === '' ? '无系列' : item.storeSeriesName }}</p>
              </el-col>
              <el-col :span="12">
                <p class="pTitle" style="text-align: right;font-weight: bold;font-size: 13px">
                  {{ item.styleNum === '' ? '暂无款式编号' : item.styleNum }}</p>
              </el-col>
            </el-row>
            <el-row v-if="item.tenantCrop===tenantCrop">
              <el-col :span="12">
                <el-link :type="item.publishStatus===0?'success':item.publishStatus===1?'warning':''" @click="updateById(item)">
                  {{item.publishStatus===0?'上架':item.publishStatus===1?'下架':''}}
                </el-link>
              </el-col>
            </el-row>

          </div>
        </el-col>
      </el-row>
      <p v-loading="loading" element-loading-text="加载中"></p>
      <p v-if="noMore">没有更多了...</p>
    </div>
    <styleStoreDetails v-if="dialog.openEditState" :styleInfo="dialog.styleInfo" :state="dialog.openEditState"
                       @close="close"/>
    <styleStoreImage v-if="dialog.openImageState" :styleInfo="dialog.styleInfo" :state="dialog.openImageState"
                     @close="close"/>
  </div>
</template>

<script>
import styleStoreDetails from "@/pages/store/style-store/style-store-details";
import styleStoreImage from "@/pages/store/style-store/style-store-image";

export default {
  name: "style-store-list",
  data() {
    return {
      form: {
        storeTypeId: "",
        storeLabelIds: "",
        publishStatus: "1",
      },
      storeStyleTypeIdArray: [],
      storeLabelIdArray: [],
      dialog: {
        openEditState: false,
        openImageState: false,
        openStylePrice: false,
        styleInfo: {},
      },

      page: 1,
      styleList: [],
      total: 0,
      loading: true,
      disabled: true,
      noMore: false,
      tenantCrop: localStorage.getItem("tenantCrop"),
    }
  },
  created() {
    this.queryStyleList();
    this.queryStoreStyleTypeArr();
    this.queryStoreStyleLabelArr();
  },
  components: {
    styleStoreDetails, styleStoreImage
  },
  methods: {
    search() {
      this.styleList = [];
      this.page = 1;
      this.total = 0;
      this.queryStyleList();
    },
    //查询款式列表
    queryStyleList: function () {
      this.$axios({
        method: "GET",
        url: "/storeStyle/queryList",
        params: {
          storeTypeId: this.form.storeTypeId,
          storeLabelIds: this.form.storeLabelIds.toString(),
          publishStatus: this.form.publishStatus,
          page: this.page,
          limit: 30,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.styleList.push(...response.data.data.list);
        this.total = response.data.data.total;
        this.loading = false
        this.disabled = false;
      })
    },
    updateById(item){
      if (![0,1].includes(item.publishStatus)){
        this.$message.error("已出售款式不支持上下架!");
        return
      }
      let text=item.publishStatus===0?'是否确认上架该款式?':item.publishStatus===1?'是否确认下架该款式?':''
      this.$confirm(text,  {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$axios({
          method:"POST",
          url:"/storeStyle/updateStoreStyle",
          data:{
            id:item.id,
            publishStatus:item.publishStatus===0?1:0,
          }
        }).then(response=>{
          if(response.data.code===200){
            this.$message.success('修改成功');
            let self = this;
            setTimeout(function () {
              self.search()
            }, 500)
            return;
          }
          this.$message.error(response.data.msg);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消添加'
        });
      });
    },
    openStyleImages(storeStyleId) {
      this.$axios({
        method: "GET",
        url: "/storeStyleImage/queryLinkListByStyleId",
        params: {
          storeStyleId: storeStyleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    handleCommand(command) {
      this.$axios({
        method: "GET",
        url: "/storeStyle/queryById",
        params: {
          id: command[1].id
        }
      }).then(response => {
        this.dialog.styleInfo = response.data.data;
        console.log(this.dialog.styleInfo)
        switch (command[0]) {
          case 'edit':
            this.dialog.openEditState = true
            break;
          case 'del':
            this.delStyle();
            break;
          case 'styleImage':
            this.dialog.openImageState = true
            break;
          case 'stylePrice':
            this.dialog.openStylePrice = true
            break;
        }
      })
    },
    //查询款式类型库
    queryStoreStyleTypeArr() {
      this.$selectUtils.queryStoreStyleTypeIds().then(response => {
        this.storeStyleTypeIdArray = JSON.parse(response.data.data)
      })
    },
    //查询款式标签
    queryStoreStyleLabelArr() {
      this.$selectUtils.queryStoreLabelIds().then(response => {
        this.storeLabelIdArray = JSON.parse(response.data.data)
      })
    },
    delStyle(){
      this.$confirm('是否确认删除该款式?', '删除款式', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'error'
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/storeStyle/delStyle",
          data: {
            id: this.dialog.styleInfo.id
          }
        }).then(response => {
          let flag=response.data.code===200;
          this.$message({
            type: flag?'success':'error',
            message: flag?'删除成功':response.data.msg,
          });
          let self=this;
          setTimeout(()=>{
            self.search();
          },1000)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryStyleList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryStyleList()
    },
    //加载
    load() {
      this.disabled = true;
      if (this.styleList.length >= this.total) {
        this.noMore = true;
        this.loading = false;
        return;
      }
      this.loading = true;
      this.noMore = false;
      this.page++;
      setTimeout(() => {
        this.queryStyleList();
      }, 1000)
    },
    close() {
      this.search();
      this.dialog.openEditState = false;
      this.dialog.openImageState = false;
      this.dialog.openStylePrice = false;
    }
  }
}
</script>

<style scoped>
p {
    margin: 5% 0;
    font-size: 23px;
    text-align: center;
}

.pTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    margin: 0 0;
    font-size: 13px;
    font-weight: bold;
}

.item >>> .el-badge__content.is-fixed {
    transform: none;
    left: 0;
    right: unset;
}
</style>