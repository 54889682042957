<template>
  <div>
    <fieldset style="width:97%" v-show="isMe">
      <legend></legend>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button icon="el-icon-picture-outline" @click="drawer = true" size="medium"
                     style="background-color: var(--my-warning-color);color: whitesmoke">上传图片</el-button>
        </el-col>
        <el-col :span="6">
          <el-button icon="el-icon-video-camera-solid" @click="drawerVideo = true" size="medium"
                     style="background-color: var(--my-error-color);color: whitesmoke">上传视频</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-collapse style="width:97%;margin: 0 auto" v-model="activeImage">
      <el-collapse-item title="款式视频">
        <el-row style="margin: 1% 0" v-for="(item,index) in styleVideoList" :key="index" >
          <div>
            <video
                controls
                preload="none"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source :src="`https://style-video-1304365928.cos.ap-shanghai.myqcloud.com/${item.storeStyleVideo}`"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </div>
          <el-row style="text-align: center;cursor:pointer;">
            <el-popconfirm
                @confirm="delVideo(item.id)"
                title="是否确认删除该视频?"
            >
              <el-button slot="reference" size="mini">删除</el-button>
            </el-popconfirm>
          </el-row>
        </el-row>
      </el-collapse-item>
      <el-collapse-item v-for="(item,index) in styleImageList" :key="item.id" :title="item.storeImageTypeName" :name="index">
        <el-col :span="12"  style="margin: 1% 0" v-for="(itemChild,indexChild) in item.image" :key="indexChild">
          <div>
            <el-image
                style="width: 270px; height: 320px;" @click="openImage(itemChild.styleImage)"
                :src="`https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/${itemChild.styleImage}?imageMogr2/rquality/60`" fit="contain"/>
          </div>
          <el-row style="text-align: center;width: 250px;cursor:pointer;">
            <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ itemChild.storeImageTypeName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-star-off" :command="[1,itemChild.styleImage,indexChild]">设为主图</el-dropdown-item>
                <el-dropdown-item icon="el-icon-magic-stick" :command="[2,itemChild.id,indexChild]">
                  <el-dropdown trigger="click" @command="changeImageType" placement="top">
                  <span class="el-dropdown-link">
                    更改图片类型<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="(type,tIndex) in imageTypeIdArray"
                                        icon="el-icon-star-off"
                                        :key="tIndex"
                                        :command="[itemChild.id,type,indexChild]">{{ type.name }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-circle-close" :command="[3,itemChild.id,indexChild]">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-row>
        </el-col>
      </el-collapse-item>
    </el-collapse>
    <!--    <el-row :gutter="50" style="overflow-y: auto;height: 650px;">-->

    <!--    </el-row>-->
    <el-drawer
        title="上传图片"
        :visible.sync="drawer"
        :append-to-body="true">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="15">
            <el-form-item label="图片类型" prop="storeImageTypeId">
              <el-select v-model="form.storeImageTypeId" placeholder="图片类型" clearable>
                <el-option
                    v-for="item in imageTypeIdArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" icon="el-icon-upload2" @click="addImage" :loading="loading">上 传</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-row style="margin-top: 30px">
        <el-col :span="16" :offset="6">
          <div>
            <el-row style="margin-top: 30px">
              <UploadImages @files="getFiles" :img-size="0"/>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
    <el-drawer
        class="addVideoClass"
        title="上传视频"
        :visible.sync="drawerVideo"
        :append-to-body="true">
      <el-row>
        <el-col :span="5" :offset="6">
          <el-button type="primary" icon="el-icon-upload2" @click="addVideo" :loading="loading">上 传</el-button>
        </el-col>

      </el-row>
      <el-row style="margin-top: 30px">
        <el-col :span="16" :offset="6">
          <div>
            <el-row style="margin-top: 30px">
              <UploadVideos @files="getVideoFiles" :img-size="0"/>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
<!--  </el-dialog>-->
  </div>
</template>

<script>
import UploadImages from "@/components/image/uploadImages.vue";
import UploadVideos from "@/components/image/uploadVideos.vue";
export default {
  name: "style-store-image",
  data() {
    return {
      form: {
        storeImageTypeId: "",
        imageNames: [],
        videoNames:[],
        storeStyleId: this.styleInfo.id,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      styleImageList: [],
      styleVideoList: [],
      imageTypeIdArray: [],
      activeImage:[],

      rules: {
        storeImageTypeId: [
          {required: true, message: '请选择图片类型', trigger: 'change'},
        ],
      },
      dialogState: this.state,
      loading: false,
      drawer: false,
      styleImage: {
        files: [],
      },
      styleVideos: {
        files: [],
      },
      drawerVideo:false,
      //该款式是此登陆店铺的吗？
      isMe:false,
    }
  },
  components: {
    UploadImages,UploadVideos
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryStyleImage();
    this.queryStyleVideo();
    this.queryImageTypeIdsArr();
    this.isMe=this.form.tenantCrop===this.styleInfo.tenantCrop;
  },
  methods: {
    //添加图片
    addImage() {
      this.$refs['form'].validate(valid=> {
        if (valid) {
          if (this.styleImage.files<1) {
            this.$message({
              showClose: true,
              message: "请选择图片",
              type: 'warning',
              duration: 1500,
            })
            return
          }
          this.loading = true
          this.uploadStyleImage().then(value => {
            if (!value) {
              this.$message({
                showClose: true,
                message: "图片上传发生错误,请检查后重新上传",
                type: 'error',
                duration: 1500,
              })
            } else {
              this.$axios({
                method: "PUT",
                url: "/storeStyleImage/addStoreStyleImages",
                data: {
                  ...this.form
                }
              }).then(response => {
                let flag = response.data.code === 200
                this.$message({
                  showClose: true,
                  message: flag ? "图片添加成功" :response.data.msg,
                  type: flag? 'success' :'error',
                  duration: 1000,
                })
                if (flag) {
                  this.drawer = false
                  this.queryStyleImage()
                  this.styleImage.files=[];
                  this.form.imageNames=[]
                }
                this.loading = false
              })
            }
          })
        }
      })
    },
    //添加视频
    addVideo() {
      if (this.styleVideos.files<1) {
        this.$message({
          showClose: true,
          message: "请选择视频",
          type: 'warning',
          duration: 1500,
        })
        return
      }
      this.loading = true
      this.uploadStyleVideo().then(value => {
        if (!value) {
          this.$message({
            showClose: true,
            message: "视频上传发生错误,请检查后重新上传",
            type: 'error',
            duration: 1500,
          })
        } else {
          this.$axios({
            method: "PUT",
            url: "/storeStyleVideo/addVideo",
            data: this.form
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "视频添加成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.drawerVideo = false
              this.queryStyleVideo()
              this.styleVideos.files=[];
              this.form.videoNames=[]
            }
            this.loading = false
          })
        }
      })
    },
    //查询款式图片
    queryStyleImage() {
      this.$axios({
        method: "GET",
        url: "/storeStyleImage/queryByStoreStyleId",
        params: {
          storeStyleId: this.styleInfo.id
        }
      }).then(response => {
        this.styleImageList = []
        this.styleImageList.push(...this.arrGroupBy(response.data.data));
        for(let index in this.styleImageList){
          this.activeImage.push(Number(index))
        }
      })
    },
    //查询款式视频
    queryStyleVideo() {
      this.$axios({
        method: "GET",
        url: "/storeStyleVideo/queryList",
        params: {
          styleId: this.styleInfo.id
        }
      }).then(response => {
        this.styleVideoList = response.data.data
      })
    },
    //下拉菜单选项
    handleCommand(command) {
      switch (command[0]) {
        case 1:
          this.mainImage(command);
          break;
        case 3:
          this.delImage(command);
          break;
      }
    },

    //设为主图
    mainImage(command) {
      this.$confirm("是否将第" + (command[2] + 1) + "张图设为该款式主图", '设置主图', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/storeStyle/updateMainImage",
          data: {
            id: this.styleInfo.id,
            mainImage: command[1],
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //下拉菜单更改图片类型
    changeImageType(command) {
      this.$confirm("是否将第" + (command[2] + 1) + "张图设为“" + command[1].name + "” ?","修改图片类型", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/storeStyleImage/changeImageType",
          data: {
            id: command[0],
            storeImageTypeId: command[1].value,
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改图片类型成功!")
            this.queryStyleImage();
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //删除款式图
    delImage(command) {
      this.$confirm("是否将第" + (command[2] + 1) + "张图删除", '删除图片', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/storeStyleImage/deleteById",
          data: {
            id: command[1]
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("删除图片成功")
            this.queryStyleImage();
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    delVideo(id) {
      this.$axios({
        method: "POST",
        url: "/storeStyleVideo/delById",
        data: {
          id: id
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("删除视频成功")
          this.queryStyleVideo();
        } else {
          this.$message.error(response.data.msg)
        }
      });
    },
    //放大图片
    openImage(link) {
      this.$viewerApi({
        images: ['https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/' + link],
      })
    },
    //查询款式类型
    queryImageTypeIdsArr() {
      this.$selectUtils.queryStoreStyleImageTypeIds().then(response => {
        this.imageTypeIdArray = JSON.parse(response.data.data);
      })
    },
    //图片根据类型分组
    arrGroupBy(array){
      if (array===undefined||array===null||array===[]||array.length===0) return [];
      //获取去重后的图片类型
      let typeName=[];
      array.map(k=>{return k.storeImageTypeName}).forEach(k=>{
        if (!typeName.includes(k)){
          typeName.push(k)
        }
      })

      let newArr=[];
      typeName.forEach(k=>{
        let arrItem={storeImageTypeName:k,image:[]}
        array.forEach(v=>{
          if(k===v.storeImageTypeName){
            arrItem.image.push(v)
          }
        })
        newArr.push(arrItem)
      })
      return newArr;
    },
    close: function () {
      this.$emit("close", false);
    },
    uploadStyleImage: function () {
      return new Promise((resolve, reject) => {
        if (this.styleImage.files < 1) {
          return resolve(true)
        }
        //构建腾讯需要的文件对象
        let buildFlies = this.buildFiles();
        this.$upload.getTemporaryKey(this.$upload.clothesImage).uploadFiles({
          files: buildFlies,
          SliceSize: 1024 * 1024 * 50, //图片大于50M则分块上传
        }, function (err, data) {
          let error = false;
          data.files.forEach(k => {
            //如果不等于null 则说明图片上传有误 则返回true
            error = k.error !== null ? true : error;
          })
          if (err !== null || error) {
            this.$message.error("上传失败,请刷新重试!")
            resolve(false)
          } else {
            resolve(true)
          }
        })

      })
    },
    uploadStyleVideo: function () {
      return new Promise((resolve, reject) => {
        if (this.styleVideos.files < 1) {
          return resolve(true)
        }
        //构建腾讯需要的文件对象
        let buildFlies = this.buildVideoFiles();
        this.$upload.getTemporaryKey(this.$upload.styleVideo).uploadFiles({
          files: buildFlies,
          SliceSize: 1024 * 1024 * 50, //图片大于50M则分块上传
        }, function (err, data) {
          let error = false;
          data.files.forEach(k => {
            //如果不等于null 则说明图片上传有误 则返回true
            error = k.error !== null ? true : error;
          })
          if (err !== null || error) {
            this.$message.error("上传失败,请刷新重试!")
            resolve(false)
          } else {
            resolve(true)
          }
        })

      })
    },
    //从子组件获取file
    getFiles(file) {
      this.styleImage.files = file;
    },
    //
    getVideoFiles(file){

      this.styleVideos.files = file;
      console.log(this.styleVideos.files)
    },
    //构建多文件上传时的格式
    buildFiles() {
      let buildFiles = [];
      this.styleImage.files.forEach(k => {
        let arr = k.name.split(".");
        let fileName = this.$md5(arr[0] + new Date()) + "." + arr[arr.length - 1];
        this.form.imageNames.push(fileName);
        buildFiles.push({
          Bucket: this.$upload.getBucketName(this.$upload.clothesImage), /* 填入您自己的存储桶，必须字段 */
          Region: this.$upload.getRegion(),  /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: fileName,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: k.raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
        })
      })
      return buildFiles;
    },
    //构建多文件上传时的格式
    buildVideoFiles() {
      let buildFiles = [];
      this.styleVideos.files.forEach((fileItem,index) => {
        let arr = fileItem.name.split(".");
        let fileName = this.$md5(arr[0] + new Date().getTime()+index) + "." + arr[arr.length - 1];
        this.form.videoNames.push(fileName);
        buildFiles.push({
          Bucket: this.$upload.getBucketName(this.$upload.styleVideo), /* 填入您自己的存储桶，必须字段 */
          Region: this.$upload.getRegion(),  /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: fileName,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: fileItem.raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
        })
      })
      return buildFiles;
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  margin-top: 5% !important;
}

</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 280px;
  height: 410px;
  display: block;
}
.addVideoClass .el-upload-list--picture-card .el-upload-list__item{
  width: revert;
  height: revert;
}
/* 按钮样式 */
.custom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
}

.custom-controls button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}
</style>